export default (city) => {
  let caseOwner

  switch (city) {
    case 'SOROCABA':
      caseOwner = 'MRO - SOD'
      break
    case 'MELBOURNE':
      caseOwner = 'MRO - MLB'
      break
    case 'GAVIÃO PEIXOTO':
      caseOwner = 'MRO - GPX'
      break
    case 'FORT LAUDERDALE':
      caseOwner = 'MRO - FLL'
      break
    case 'MESA':
      caseOwner = 'MRO - IWA'
      break
    default:
      break
  }

  return caseOwner
}
