import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button, Grid, TextareaAutosize, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dialog from '../../component/dialog'
import DisputeForm from './dispute-form'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import StyledDropzone from '../../component/dropzone'
import FileList from '../../pages/customer-support/file-list'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Loading from '../../component/loading'
import ReviewTable from './review-table'
import Swal from 'sweetalert2'
import { isEmployee } from '../../domain/security'
import Dropdown from '../../component/dropdown'
import disputeReasonsKeys from './disputeReasonsAttachMessages.json'
import getQuantumCaseOwner from '../../helpers/get-quantum-case-owner'

const getDisputeRows = (state) => get(state, 'getDisputeRows.selects', [])
const getFilters = (state) => get(state, 'getFilters.selects', [])
const getAuth = (state) => get(state, 'auth.user')

const caseOwners = {
  executive: process.env.REACT_APP_DISPUTE_EXECUTIVE_OWNERS.split(','),
  commercial: process.env.REACT_APP_DISPUTE_COMMERCIAL_OWNERS.split(','),
  defense: process.env.REACT_APP_DISPUTE_DEFENSE_OWNERS.split(',')
}

const disputeReasons = [
  '1. Missing Material - total quantity',
  '2. Missing Material - partial quantity',
  '3. Over shipment - Embraer has shipped more than ordered and charged the over units',
  '4. Over shipment - Embraer has shipped more than ordered but did not charge for the over units',
  '5. Pricing discrepancy',
  '6. Damaged Material',
  '7. Missing Documentation',
  '8. PO Cancelled',
  '9. Global Quote Invoice issued by mistake',
  '10. Invalid PO reference',
  '11. RTS Service charge not applicable',
  '12. CID (Customer Induced Damage) Cases',
  '13. Reimbursement',
  '14. Lost core',
  '15. NFF (No Fault Found)',
  '16. Other'
]

export default (props) => {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState(0)
  const [description, setDescription] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedTotalSize, setUploadedTotalSize] = useState(0)
  const rows = useSelector(getDisputeRows)
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [formValues, setFormValues] = useState([])
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const filters = useSelector(getFilters)
  const customerSelected = filters.filter(
    (filter) => filter.name === 'customer'
  )
  const [descriptionError, setDescriptionError] = useState(false)
  const [selectedCaseOwner, setSelectedCaseOwner] = useState('')
  const [selectedDisputeReason, setSelectedDisputeReason] = useState('')
  const user = useSelector(getAuth)
  const isComercial = rows.length && rows[activeStep].distributionChannel === 10
  const isDefense = rows.length && rows[activeStep].distributionChannel === 20

  const [disputeReasonError, setDisputeReasonError] = useState(false)
  const [reasonForDisputeError, setReasonForDisputeError] = useState(false)
  const [attachInfo, setAttachInfo] = useState({
    message: 'dispute-default-attach-message'
  })
  const [caseOwnerSelectedError, setCaseOwnerSelectedError] = useState(false)

  const handleBack = async (event) => {
    event.preventDefault()

    let newValues = formValues
    newValues[activeStep] = {
      ...newValues[activeStep],
      description,
      ...rows[activeStep],
      uploadedFiles,
      uploadedTotalSize,
      caseOwner: selectedCaseOwner,
      disputeReason: selectedDisputeReason
    }

    setFormValues(newValues)
    setDisputeReasonError(false)

    setActiveStep(activeStep - 1)
    if (formValues[activeStep - 1]) {
      setDescription(formValues[activeStep - 1].description)
      setUploadedFiles(formValues[activeStep - 1].uploadedFiles)
      setUploadedTotalSize(formValues[activeStep - 1].uploadedTotalSize)
      setSelectedCaseOwner(formValues[activeStep - 1].caseOwner)
      setSelectedDisputeReason(formValues[activeStep - 1].disputeReason)
    }
  }

  const handleAttach = useCallback(
    async (files) => {
      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))

      uploadedFiles.forEach((file) => (totalFilesSize += file.size))

      if (totalFilesSize > 25000000) {
        return Swal.fire(
          'Attention!',
          'The maximum size supportted is 25Mb, please select smaller files.',
          'warning'
        )
      }

      if (uploadedFiles.length + files.length > 5) {
        return Swal.fire(
          'Attention!',
          'The maximum amount of files has been exceeded, please select up to 5 files.',
          'warning'
        )
      }

      setUploadedTotalSize(totalFilesSize)
      setDisputeReasonError(false)

      return setUploadedFiles([
        ...uploadedFiles,
        ...files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ])
    },
    [uploadedFiles]
  )

  const onDropRejected = (rejectedFiles) => {
    const rejectedFileNames = rejectedFiles
      .map((file) => file.file.name)
      .join(', ')

    setDisputeReasonError(false)

    return Swal.fire(
      'Attention!',
      `The file format for ${rejectedFileNames} is not allowed. Please, select only pdf, jpeg and png formats with the maximum total size of 25mb.`,
      'warning'
    )
  }

  const handleDelete = useCallback(
    (index) => {
      const files = uploadedFiles
      files.splice(index, 1)
      setUploadedFiles([...files])

      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))

      setUploadedTotalSize(totalFilesSize)
    },
    [uploadedFiles]
  )

  const defineCaseOwnersList = () => {
    if (isComercial) return caseOwners.commercial.map((owner) => owner)
    if (isDefense) return caseOwners.defense.map((owner) => owner)
    return caseOwners.executive.map((owner) => owner)
  }

  const DisputeFormMemo = useMemo(
    () => (
      <DisputeForm key={activeStep} docs={rows[activeStep]} filters={filters} />
    ),
    [activeStep, props.selecteds]
  )

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isDefense && !selectedCaseOwner && isEmployee(user))
      return setCaseOwnerSelectedError(true)

    if (!selectedDisputeReason && isComercial)
      return setReasonForDisputeError(true)

    if (!description) {
      return setDescriptionError(true)
    }

    if (
      isComercial &&
      !uploadedFiles.length &&
      ![
        '1. Missing Material - total quantity',
        '3. Over shipment - Embraer has shipped more than ordered and charged the over units',
        '7. Missing Documentation',
        '9. Global Quote Invoice issued by mistake',
        '12. CID (Customer Induced Damage) Cases',
        '16. Other'
      ].includes(selectedDisputeReason)
    ) {
      return setDisputeReasonError(true)
    }

    let newValues = formValues
    newValues[activeStep] = {
      ...newValues[activeStep],
      description,
      ...rows[activeStep],
      uploadedFiles,
      uploadedTotalSize,
      caseOwner: selectedCaseOwner,
      disputeReason: selectedDisputeReason,
      isComercial
    }

    setFormValues(newValues)

    if (activeStep + 1 === rows.length) {
      props.setIsDialogOpen(false)
      setIsReviewDialogOpen(!isReviewDialogOpen)

      if (!customerName && customerSelected.length > 0)
        setCustomerName(customerSelected[0].selected.label)

      return
    }

    setActiveStep(activeStep + 1)
    setUploadedFiles([])
    setUploadedTotalSize(0)
    setSelectedCaseOwner(
      !!rows[activeStep + 1].caseOwner ? rows[activeStep + 1].caseOwner : ''
    )
    setSelectedDisputeReason('')
    setDisputeReasonError(false)

    const verify = newValues.find((e) => e.id === rows[activeStep + 1].id)

    if (!verify) {
      newValues[activeStep + 1] = {
        ...newValues[activeStep + 1],
        isSameOfPrevious: false,
        description: ''
      }
    }

    if (formValues[activeStep + 1]) {
      setDescription(formValues[activeStep + 1].description)
      setUploadedFiles(get(formValues[activeStep + 1], 'uploadedFiles', []))
      setUploadedTotalSize(
        get(formValues[activeStep + 1], 'uploadedTotalSize', 0)
      )
      setSelectedCaseOwner(get(formValues[activeStep + 1], 'caseOwner', ''))
      setSelectedDisputeReason(
        get(formValues[activeStep + 1], 'disputeReason', '')
      )
    } else {
      setDescription('')
    }
  }

  const handleDescription = (e) => {
    if (e.target.checked) {
      const checkedForm = [...formValues]
      checkedForm[activeStep].isSameOfPrevious = true

      setFormValues(checkedForm)
      if (descriptionError) setDescriptionError(!descriptionError)
      return setDescription(formValues[activeStep - 1].description)
    }
    const checkedForm = [...formValues]
    checkedForm[activeStep].isSameOfPrevious = false
    setFormValues(checkedForm)
    return setDescription('')
  }

  const clearValues = async () => {
    setDescription('')
    setUploadedFiles([])
    setActiveStep(0)
    setFormValues([])
    setSelectedDisputeReason('')
    setDisputeReasonError(false)
    setReasonForDisputeError(false)
    setSelectedCaseOwner()
    setCaseOwnerSelectedError(false)
  }

  const handleClose = () => {
    Swal.fire({
      title: t('dispute-cancel-title'),
      text: t('dispute-cancel-alert-message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('dispute-case-delete-confirm-button'),
      cancelButtonText: t('user-cancel-button')
    }).then((result) => {
      setActiveStep(0)
      if (result.value) {
        if (props.isDialogOpen) props.setIsDialogOpen(!props.isDialogOpen)
        if (isReviewDialogOpen) setIsReviewDialogOpen(!isReviewDialogOpen)
        clearValues()
      }
    })
  }

  const handleSelectCaseOwner = (event) => {
    setSelectedCaseOwner(event.target.value)
    setCaseOwnerSelectedError(false)
  }

  const handleSelectDisputeReason = (event) => {
    setSelectedDisputeReason(event.target.value)
    const disputeReason = disputeReasonsKeys.find(
      (key) => key.option === event.target.value
    )
    setAttachInfo(disputeReason)

    setDisputeReasonError('')
    setReasonForDisputeError(false)
  }

  useEffect(() => {
    if (!customerName && customerSelected.length > 0)
      setCustomerName(customerSelected[0].selected.label)

    if (rows.length > 0 && rows[activeStep].caseOwner) {
      setSelectedCaseOwner(rows[activeStep].caseOwner)
    }

    if (
      rows.length > 0 &&
      rows[activeStep].salesOrganization === 'EEJS' &&
      !rows[activeStep].caseOwner
    ) {
      setSelectedCaseOwner(getQuantumCaseOwner(rows[activeStep].city))
    }

    if (loadingStatus) {
      setLoadingStatus(false)
    }
  }, [rows, loadingStatus, activeStep])

  return (
    <Fragment>
      <Dialog
        open={props.isDialogOpen}
        labelClose
        onClose={handleClose}
        label={<h1>Dispute Request</h1>}
        maxWidth='sm'
        style={{ zIndex: '500', minHeight: '500px' }}>
        {loadingStatus ? (
          <Loading />
        ) : (
          <Fragment>
            {DisputeFormMemo}
            {activeStep > 0 && !isComercial ? (
              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      onChange={handleDescription}
                      checked={
                        formValues[activeStep]
                          ? formValues[activeStep].isSameOfPrevious
                          : false
                      }
                      color='blue'
                      error={descriptionError}
                    />
                  }
                  label={t('dispute-checkbox-message')}
                />
              </Grid>
            ) : null}
            {isEmployee(user) ? (
              <Grid
                item
                xs={12}
                lg={12}
                style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Dropdown
                  options={defineCaseOwnersList()}
                  label='Case Owner'
                  handleChange={handleSelectCaseOwner}
                  defaultValue={selectedCaseOwner}
                  selectedValue={selectedCaseOwner}
                  size={'180px'}
                  fontSize={'15px'}
                  placeholderSize={'13px'}
                  error={caseOwnerSelectedError}
                />
                {caseOwnerSelectedError ? (
                  <Typography style={{ color: 'red', fontSize: '15px' }}>
                    {t('dispute-defense-case-owner-empty-error')}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            {isComercial ? (
              <Fragment>
                <Grid item style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                  <Dropdown
                    options={disputeReasons}
                    label={`${t('dispute-reason-label')} *`}
                    handleChange={handleSelectDisputeReason}
                    selectedValue={selectedDisputeReason}
                    size={'100%'}
                    fontSize={'15px'}
                    placeholderSize={'12px'}
                    error={reasonForDisputeError}
                  />
                </Grid>
                {reasonForDisputeError ? (
                  <Typography style={{ color: 'red', fontSize: '15px' }}>
                    {t('dispute-commercial-reason-for-dispute')}
                  </Typography>
                ) : null}
              </Fragment>
            ) : null}
            <Grid item xs={12} lg={12}>
              <Typography
                variant='h6'
                style={{ color: isComercial ? null : 'blue' }}>
                {`${
                  isComercial
                    ? t('dispute-commercial-comments')
                    : t('dispute-reason-label')
                } *`}
              </Typography>
              <TextareaAutosize
                required
                name='description'
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value)
                  if (description && descriptionError)
                    setDescriptionError(!descriptionError)
                }}
                style={{
                  width: '100%',
                  minHeight: '100px',
                  maxHeight: '300px',
                  borderColor: descriptionError ? 'red' : 'initial'
                }}
              />
              {descriptionError ? (
                <Typography style={{ color: 'red', fontSize: '15px' }}>
                  {t('dispute-description-error-message')}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={12} style={{ marginLeft: '25px' }}>
              <StyledDropzone
                className='files-dropzone'
                onDrop={handleAttach}
                accept={{
                  'image/*': ['.jpeg', '.jpg', '.png'],
                  'application/pdf': ['.pdf']
                }}
                multiple
                message={attachInfo.message}
                color={attachInfo.color}
                fontWeight={attachInfo.fontWeight}
                onDropRejected={onDropRejected}
                isRequired={disputeReasonError}
                t={t}
              />
            </Grid>
            <Grid item xs={12} lg={12} style={{ marginBottom: '10px' }}>
              <FileList
                files={uploadedFiles}
                handleDelete={handleDelete}
                t={t}
                uploadedTotalSize={uploadedTotalSize}
              />
            </Grid>
            <Grid
              item
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='contained'
                color='primary'
                disabled={activeStep === 0}
                onClick={handleBack}>
                {t('back')}
              </Button>
              {`${activeStep + 1}/${rows.length}`}
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}>
                {t('next')}
              </Button>
            </Grid>
          </Fragment>
        )}
      </Dialog>
      <Dialog
        open={isReviewDialogOpen}
        labelClose
        onClose={handleClose}
        label={<h1>Dispute Request - Review</h1>}
        maxWidth='sm'
        style={{ zIndex: '500' }}>
        <ReviewTable
          filters={filters}
          values={formValues}
          setIsReviewDialogOpen={setIsReviewDialogOpen}
          isReviewDialogOpen={isReviewDialogOpen}
          setFormValues={setFormValues}
          clearValues={clearValues}
          setDisputeRequestDialogOpen={props.setIsDialogOpen}
          setActiveStep={setActiveStep}
        />
      </Dialog>
    </Fragment>
  )
}
